import React, { Component } from "react"

export default class SectionNeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: ''
    }

    this.headingSmall = props.headingSmall
  }

  setActiveItem(id) {
    const selectedId = id === this.state.activeItem ? 0 : id;
    this.setState({ activeItem: selectedId });
  }

  render() {
    let props = this.props.data;
    return (
      <div>
        { props.map((item, index) => {
          return (
            <div key={ index } className="u-space-1">
              <div className="mb-3">
                <h3 className={"jd-text " + (this.headingSmall === 'true' ? 'jd-h6-faq' : 'jd-h2-titles')}>{item.accordionCategory}</h3>
              </div>
              {/* Accordion */}
              <div className="accordion-list">
                { item.accordionCard.map((subitem, subindex) => {
                  return (
                    <div key={ subindex } className="card mb-3">
                      <div className="card-header u-card-header" id="basicsHeadingOne">
                        <h5 className="mb-0">
                          <button className={"btn btn-link btn-block d-flex justify-content-between u-card-btn jd-btn-link " + (this.state.activeItem === subitem ? '' : 'collapsed')} onClick={() => this.setActiveItem(subitem)}>
                            {subitem.code ? <span className="font-weight-bold">{subitem.code}</span> : ''}
                            <h5 className="mb-0">{subitem.title}</h5>
                            <span className="u-card-btn__arrow">
                              <span className="fa fa-arrow-down small"></span>
                            </span>
                          </button>
                        </h5>
                      </div>
                      <div className={"collapse " + (this.state.activeItem === subitem ? 'show' : '')}>
                        <div className="card-body u-card-body jd-text">
                          {subitem.content}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              {/* End Accordion */}
            </div>
          )
        })}
      </div>
    )
  }
}
