import React, { Component } from "react"

import NavBar from "./NavBar"

const navLinks = ["How", "Plans", "FAQ", "Portfolio"]
const locale = "en"
const loginButtonText = "Login"

export default class Header extends Component {
  render() {
    const data = this.props

    return (
      <header
        id="header"
        className="u-header u-header--sticky-top"
        data-header-fix-moment="500"
        data-header-fix-effect="slide"
      >
        <div className="u-header__section">
          <div className="container">
            <NavBar navLinks={navLinks} locale={locale} loginButtonText={loginButtonText} navBarComponents={data} />
          </div>
        </div>
      </header>
    )
  }
}
