import React from "react"

import Layout from "../layouts/en"
import SEO from "../components/SEO"
import Metomic from "../components/Metomic"
import SplashScreens from "../components/SplashScreens"
import Header from "../components/Header.en"
import Accordions from "../components/Accordions";

import 'react-bootstrap-typeahead/css/Typeahead.css';

const accordionData = [
  {
    accordionCategory: 'Introduction',
    accordionCard: [
      {
        title: "What do you mean by unlimited designs?",
        content: "You can request as many designs as you wish and your orders will be processed one by one in the order they are received, as soon as possible, ie we will work on one demand at a time and when you are satisfied with your current request, we will be ready to start your next project."
      }, {
        title: "How long does it take to complete a design task?",
        content: "We'll begin working on your order within a few business hours after receiving it, and then deliver your request to be reviewed within 2 to 5 business days, depending on your plan and the complexity of your request."
      }, {
        title: "Are the revisions also unlimited?",
        content: "Yes! We want you to be completely happy with your design, ask for as many adjustments as you need, and we'll work on your project until you're satisfied!"
      }, {
        title: "How long does a review take?",
        content: "Normally, each review takes at least one working day, however it may happen that you may need a more complex change, and it might take more time to complete. In that case we will provide a 'time quote for adjustment'."
      }, {
        title: "How do I submit my design tasks?",
        content: "Initially, you will need to fill out our specially crafted design request form, which you will find in your dashboard after your onboarding process is completed."
      }, {
        title: "Do you deliver a physical product?",
        content: "No. Our designs are 100% digital!"
      }, {
        title: "What design tools are used to create my projects?",
        content: "We use the latest versions of Adobe Creative Suite, especially Photoshop, Illustrator, and InDesign - the most commonly used tools for creating creative projects. If your plan includes web design we can also provides Sketch files."
      }, {
        title: "Does Jubarte do all kinds of design work?",
        content: "As long as it's a request for static graphic design, we do! Here are some examples of projects we don't do: video editing, animations, CAD, 3D work, programming and coding. Still, if you want to check out a specific project, don't think twice, ask one of our chat attendants or email us at support@jubarte.design."
      }, {
        title: "What is a time quote?",
        content: "Time quotes are usually provided for complex requests. Every time our team receives a complex request from you, your designer or account manager will provide an estimation of how long it takes to complete your request. That's what we call a 'time quote'."
      }
    ]
  },
  {
    accordionCategory: 'Service',
    accordionCard: [
      {
        title: "Can I talk to my designer?",
        content: "Of course you can! Your designer and / or your account manager are available to talk to you by email or chat."
      },
      {
        title: "What if I have a complex design request?",
        content: "Tell us what you need! We will evaluate and, if we can serve you, we will provide you with a custom time quote."
      },
      {
        title: "Do you work on weekends?",
        content: "No, on weekends our team like to explore deep waters to meditate in preparation for brighter design ideas on the next week. :)"
      },
      {
        title: "But what if I have more questions?",
        content: "Do not worry. Feel free to email us at support@jubarte.design or contact us via chat."
      }
    ]
  },
  {
    accordionCategory: "Plans",
    accordionCard: [
      {
        title: "Can I change my plan?",
        content: "Of course! You can change your plan whenever you need. Just contact us at support@jubarte.design and we will be happy to change it for you."
      },
      {
        title: "Are there any hidden fees?",
        content: "Absolutely no hidden fees. The price of the plan is exactly what you pay monthly. All taxes and fees are included in this price as well."
      },
      {
        title: "If I change my mind and want to cancel my subscription, can I get my money back?",
        content: "We can only give your money back if you change your mind before submitting a request to your designer in up to 15 days after the subscription starts. Once you pay the monthly amount, your account is active, which means your team will be ready and waiting for your requests."
      }
    ]
  },
]

const HelpPage = props => (
  <Layout location={props.location}>
    <Metomic />
    <SEO
      title="Help Center | Jubarte Design"
      locale="en"
      altLocale="pt"
      pathname="/help/"
      description="Check out our Help Center to learn how to make the most of Jubarte Design. Learn how our team can satisfy your graphic and web design needs."
    />
    <SplashScreens />
    <Header showLanguageSelector />
    <div className="position-relative text-center u-space-3-top">
      <div className="container u-space-3-top u-space-2-top--md u-space-0-bottom">
        <div className="w-md-80 w-lg-60 text-center mx-auto form-search-wrapper">
          <h1 className="display-4 font-size-48--md-down jd-h2-titles">
            How can we <span className="font-weight-bold">help?</span>
          </h1>
        </div>
      </div>
    </div>

    {/* FAQ Topics Section */}
    <div className="section-faq-list container u-space-1">
      <Accordions data={accordionData} />
    </div>
    {/* End FAQ Topics Section */}
  </Layout>
)

export default HelpPage
