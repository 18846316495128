import React, { Component } from "react"
import { Link } from "gatsby"

const listLinks = [
  {
    type: "internal",
    path: "/en/pricing/",
    text: "Pricing",
  },
  {
    type: "internal",
    path: "/en/help/",
    text: "Help Center",
  },
  {
    type: "internal",
    path: "/en/terms-of-use/",
    text: "Terms of Use",
  },
  {
    type: "internal",
    path: "/en/privacy-policy/",
    text: "Privacy Policy",
  },
]

const socialLinks = [
  {
    path: "https://www.linkedin.com/company/jubartedesign",
    icon: "linkedin",
    ariaLabel: "Link to our LinkedIn page",
  },
  {
    path: "https://www.instagram.com/jubarte_design/",
    icon: "instagram",
    ariaLabel: "Link to our Instagram page",
  },
  {
    path: "https://twitter.com/jubartedesign",
    icon: "twitter",
    ariaLabel: "Link to our Twitter",
  },
  {
    path: "https://www.facebook.com/jubarte.design/",
    icon: "facebook",
    ariaLabel: "Link to our Facebook page",
  },
]

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer position-relative jd-gradient-half-primary-v5">
        <div className="container u-space-4-top u-space-2-bottom">
          {/* <!-- Title --> */}
          <div className="footer-heading text-center mx-auto">
            <h2 className="jd-h2-titles">
              Ready <strong className="font-weight-bold">to get started?</strong>
            </h2>
            <p className="jd-text mb-0">
              "From beach kiosks to bloggers, from startups to big companies, Jubarte is changing the way designers,
              developers, entrepreneurs and other professionals deal with the needs of day-to-day graphic design."{" "}
            </p>
            <br />
          </div>
          {/* <!-- End Title --> */}

          <div className="container u-space-0 text-center">
            <a className="btn u-btn-wide rounded-pill jd-footer-btn-primary vrlps-trigger" href={"/en/#"}>
              Sign up Now!
            </a>
          </div>

          <div className="row align-items-lg-center u-space-2-top footer-bottom">
            <div className="col-lg-3 mb-4 mb-lg-0">
              {/* <!-- Copyright --> */}
              <p className="small jd-text mb-0">© jubarte.design — 2019</p>
              {/* <!-- End Copyright --> */}
            </div>

            <div className="col-md-8 col-lg-6 mb-4 mb-md-0">
              {/* <!-- List --> */}
              <ul className="list-inline text-lg-center u-list u-list--white">
                {listLinks.map((link, i) => {
                  return (
                    <li key={i} className="list-inline-item u-list__item">
                      {link.type === "internal" ? (
                        <Link to={link.path} className="jd-list__link">
                          {link.text}
                        </Link>
                      ) : (
                        <a href={link.path} className="jd-list__link">
                          {link.text}
                        </a>
                      )}
                    </li>
                  )
                })}
              </ul>
              {/* <!-- End List --> */}
            </div>

            <div className="col-md-4 col-lg-3">
              {/* <!-- Social Networks --> */}
              <ul className="list-inline text-md-right mb-0">
                {socialLinks.map((link, i) => {
                  return (
                    <li key={i} className="list-inline-item">
                      <a
                        aria-current="page"
                        rel="noopener noreferrer me"
                        target="_blank"
                        href={link.path}
                        className="u-icon jd-icon-secondary--air rounded"
                        aria-label={link.ariaLabel}
                      >
                        {link.icon === "facebook" ? (
                          <span className={"fab fa-" + link.icon + "-f u-icon__inner"}></span>
                        ) : (
                          <span className={"fab fa-" + link.icon + " u-icon__inner"}></span>
                        )}
                      </a>
                    </li>
                  )
                })}
              </ul>
              {/* <!-- End Social Networks --> */}
            </div>
          </div>

          {/* <!-- SVG Background --> */}
          <figure className="position-absolute-top-0">
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100%"
              height="140px"
              viewBox="0 0 1920 140"
              style={{ marginTop: "-2px", enableBackground: "new 0 0 1920 140" }}
            >
              <path
                className="u-fill-primary"
                opacity="0.3"
                d="M1607,81.8c0,0-274.6,48.2-560.1,19.9c-494.8-49.2-725.1,2.7-938.5,6.6C-203,114-339.2,85.6-339.2,85.6V9.4l1946.2,0V81.8z"
              />
              <path
                className="u-fill-primary"
                opacity="0.3"
                d="M2033.1,80.8c0,0-274.6,48.2-560.1,19.9c-494.8-49.2-725.1,2.7-938.5,6.6C223.1,113,86.9,84.6,86.9,84.6V8.5h1946.2V80.8z"
              />
              <path
                className="u-fill-white"
                opacity="0"
                d="M1526.9,5.6c0,0-309,96.5-698.5,96.5C406.8,102.1,201.4,7,201.4,7V1.8l1332,0L1526.9,5.6z"
              />
              <path
                className="u-fill-white"
                d="M2026.7,55.1c0,0-358.4,63.9-770,39c-523.7-31.8-712.6-8.6-938.5,6.6C-21.5,123.7-347.7,49-347.7,49l-8.5-51.3l2380.8,4.1L2026.7,55.1z"
              />
            </svg>
          </figure>
          {/* <!-- End SVG Background Section --> */}
        </div>
      </footer>
    )
  }
}
